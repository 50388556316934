/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

.rc-form .rc-block {
  margin-bottom: 0; }

.radio {
  display: inline-block;
  vertical-align: bottom; }

.radio + .radio {
  margin-top: 0;
  margin-left: 8px; }

.radio-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.radio-boxes {
  text-align: right; }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOTSTRAP OVERRIDES
 */
/* wider containr */
@media (min-width: 1250px) {
  .container {
    max-width: 1190px; } }

/* text */
h1 {
  margin-bottom: 1.8rem;
  font-size: 1.5rem;
  color: #221a1d; }

h2 {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase; }

h3 {
  margin-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase; }

h4 {
  font-size: 1rem; }

p {
  margin-bottom: 1.5rem; }

a {
  color: #000;
  text-decoration: underline; }
  a:hover, a:focus {
    color: #ff2a1c; }

/* figure */
figure {
  margin-bottom: 0; }

figcaption {
  text-align: right;
  padding-right: 3px;
  font-size: 0.7rem; }

/* button */
.btn {
  border-radius: 0; }

.btn-primary {
  padding: .375rem .5rem;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  font-size: 0.8rem; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .btn-primary:hover:active {
    color: #000;
    background-color: transparent;
    border: 1px solid #ff2a1c; }
  .btn-primary.disabled:hover {
    background-color: transparent;
    border: 1px solid #ccc;
    color: #221a1d; }

/* card */
.card {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 0;
  border: none;
  background-color: transparent; }

.card-img-top {
  border-radius: 0; }
  .card-img-top iframe {
    width: 100%; }

.card-block {
  padding: 30px;
  background-color: #fff; }
  .card-block a {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .card-block p:last-child {
    margin-bottom: 0; }
  .card-block table {
    margin-bottom: 1.5rem; }
  .card-block td {
    padding-right: 20px;
    padding-top: 3px;
    vertical-align: top; }

.card-title {
  margin-bottom: 1.5rem; }

.card-footer:last-child {
  padding: 3px 0 0;
  border-radius: 0;
  border-top: none;
  background-color: transparent;
  text-align: right;
  font-size: 0.7rem; }

/* forms */
.form-control-label {
  margin-bottom: 3px;
  color: #999999;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

.form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding: .2rem .2rem;
  font-size: 0.8rem; }
  .form-control:focus {
    border-bottom-color: #ff2a1c; }

.form-control-label {
  padding: 0 .2rem; }

.radio {
  padding-left: .2rem;
  margin-bottom: 0; }

/* breadrumb */
.breadcrumb {
  clear: both;
  background-color: #fff;
  border-radius: 0;
  margin-top: 30px;
  margin-left: 35px;
  padding: 2px; }
  .breadcrumb > li + li::before {
    content: '>'; }

@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?26358670");
  src: url("/font/fontello.eot?26358670#iefix") format("embedded-opentype"), url("/font/fontello.woff2?26358670") format("woff2"), url("/font/fontello.woff?26358670") format("woff"), url("/font/fontello.ttf?26358670") format("truetype"), url("/font/fontello.svg?26358670#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('/font/fontello.svg?26358670#fontello') format('svg');
    }
  }
  */
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-mail:before {
  content: '\e800'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  background-color: #eeeded;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

/*
 *	UTILITIES
 */
.clear::after {
  content: '';
  display: table;
  width: 0;
  clear: both; }

.border-bottom {
  border-bottom: 1px solid #000; }

.clear-me {
   clear: both; }

.relative {
  position: relative; }

.blur {
  transform: translate3D(0, 0, 0);
  filter: blur(5px);
  -webkit-filter: blur(5px); }

.hidden {
  display: none; }

/*
 *	GENERAL
 */
.red {
  color: #ff2a1c; }

.filedrop {
  width: 100%;
  height: 140px;
  border: 1px solid #ff2a1c;
  text-align: center; }
  .filedrop span {
    line-height: 140px;
    color: rgba(0, 0, 0, 0.4); }

/*
 *	MAIN
 */
.main {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.page::after {
  content: '';
  display: table;
  clear: both; }

.page__nav {
  float: left;
  width: 33%;
  padding-right: 30px; }

.page__content {
  float: left;
  width: 67%;
  position: relative; }

/*
 *	HEADER
 */
.header {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-top: 20px;
  margin-bottom: 45px; }

.logo {
  float: left; }
  .logo a {
    display: block;
    max-width: 100%;
    color: #000;
    text-decoration: none; }
  .logo a.tel {
    display: inline; }
  .logo img {
    float: left;
    width: 531px;
    max-width: 100%; }
  .logo p {
    overflow: hidden; }

.logo__img {
  float: left;
  margin-right: 10px; }

.logo__text {
  overflow: hidden; }
  .logo__text p {
    line-height: 1;
    margin-bottom: 0; }
  .logo__text .title {
    margin-bottom: 0.1rem;
    font-size: 1.5rem;
    letter-spacing: 0.1rem; }
  .logo__text .contact {
    font-size: 0.8rem; }

.social {
  float: right;
  display: block;
  margin: 0 15px 0 0;
  padding: 0;
  list-style: none; }
  .social li {
    display: inline-block; }
  .social a {
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-size: 20px; }

.dropdown--subscribe {
  float: right; }
  .dropdown--subscribe::after {
    content: '';
    display: table;
    clear: both; }
  .dropdown--subscribe .btn {
    height: 35px;
    padding: 0 5px;
    font-size: 20px;
    line-height: 30px;
    background-color: transparent; }
    .dropdown--subscribe .btn:hover {
      outline: none;
      box-shadow: none;
      color: #ff2a1c; }
  .dropdown--subscribe .dropdown-menu {
    padding: 15px;
    border-radius: 0; }

.subscribe input {
  float: left; }

.subscribe input[type="text"] {
  width: 260px;
  height: 25px;
  margin-right: 10px;
  padding: 0 5px;
  background-color: transparent;
  border: none;
  color: #000;
  border-bottom: 1px solid #221a1d;
  transition: background 0.2s ease-out; }

.subscribe input[type="submit"] {
  margin-top: 15px;
  height: 25px;
  border: none;
  border-bottom: 1px solid #221a1d;
  background-color: transparent;
  color: rgba(34, 26, 29, 0.8); }
  .subscribe input[type="submit"]:focus, .subscribe input[type="submit"]:hover, .subscribe input[type="submit"]:active {
    color: #000;
    border-bottom-color: #ff2a1c; }

.subscribe input:focus {
  outline: none;
  border-bottom-color: #ff2a1c; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(34, 26, 29, 0.8); }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(34, 26, 29, 0.8);
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(34, 26, 29, 0.8);
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(34, 26, 29, 0.8); }

/*
 *	NAV
 */
.nav-wrapper {
  position: relative; }

.nav-line {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  padding: 0;
  border: 0;
  background-color: #000; }
  .nav-line:focus {
    outline: none; }

.nav--side {
  padding-left: 25px; }
  .nav--side .nav-item {
    margin-bottom: 1rem;
    /*&:last-child { margin-bottom: 30px; }*/ }
  .nav--side .nav-link {
    color: #000;
    padding-right: 15px; }
  .nav--side a {
    line-height: 1;
    color: #000;
    text-decoration: none;
    border-bottom: 2px solid transparent; }
  .nav--side .nav-link:hover, .nav--side .nav-link:focus {
    border-bottom: 2px solid #ff2a1c; }
  .nav--side .nav-item.active > a {
    border-bottom-color: #ff2a1c; }
  .nav--side ul {
    list-style: none;
    padding-left: 0; }
  .nav--side > li > a {
    font-weight: bold;
    font-size: 1.4rem; }
  .nav--side > li > ul {
    padding-top: 1rem;
    padding-left: 20px; }
    .nav--side > li > ul > li > a {
      font-size: 1.1rem; }
  .nav--side > li > ul > li {
    margin-bottom: 1rem; }
  .nav--side > li > ul > li > ul {
    padding-top: 1rem;
    padding-left: 30px; }
    .nav--side > li > ul > li > ul li {
      margin-top: 3px; }
  .nav--side ul .active > a::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    margin-left: 7px;
    background-color: #ff2a1c; }

.toggle-nav {
  float: left;
  margin-left: -10px;
  margin-right: 16px;
  width: 25px;
  height: 60px;
  padding: 0;
  color: #000;
  background: transparent;
  border: none;
  font-size: 26px;
  outline: none;
  cursor: pointer; }
  .toggle-nav:focus {
    outline: none; }
  .toggle-nav:hover {
    color: #ff2a1c; }

/*
 *	GRID
 */
.grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px; }

.grid__sizer {
  width: 50%; }

.grid__item {
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  width: 50%;
  max-width: 50%;
  padding: 0 10px; }

/*
 *	PIC GRID
 */
.pic-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px; }

.pic-grid__sizer {
  width: 33.33%; }

.pic-grid__item {
  -ms-flex: 0 1 33.33%;
  flex: 0 1 33.33%;
  max-width: 33.33%;
  padding: 0 5px; }

/*
 *	TWO COLS
 */
.two-cols {
  display: block;
  overflow: hidden;
  margin: 0 -10px; }

.two-cols__col {
  float: left;
  width: 50%;
  padding: 0 10px; }

/*
 *	FIGURE
 */
.figure {
  max-width: 650px; }

/*
 *	PIC
 */
.pic {
  margin-bottom: 10px; }
  .pic a {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 10px; }
  .pic figcaption {
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 0 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 0.8rem; }

/*
 *	FANCYBOX
 */
.fancybox-overlay {
  background-image: none;
  background-color: rgba(229, 229, 229, 0.6); }

.fancybox-skin {
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none; }

.fancybox-opened .fancybox-skin {
  box-shadow: none; }

.fancybox-inner {
  margin-left: -5px;
  /* offset border */
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  border: 5px solid #fff;
  border-radius: 0; }

.fancybox-title {
  display: inline-block;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: bold; }

.fancybox-title-float-wrap {
  width: 100%;
  position: relative;
  right: 0;
  left: 0; }

.fancybox-title-float-wrap .child {
  width: 100%;
  margin-right: 0;
  background: none;
  color: #000;
  text-shadow: none;
  font-weight: normal; }

.fancybox-close {
  top: 0;
  right: 50%;
  margin-top: -48px;
  margin-right: -18px; }

.fancybox-nav,
.fancybox-nav:focus {
  outline: none; }

.fancybox-nav span {
  visibility: visible; }

/*
 * Footer
 */
footer {
  z-index: 1;
  position: relative;
  font-size: .8rem; }
  footer .text-center {
    text-align: center;
    padding-bottom: 5px; }

.g-recaptcha {
  margin-bottom: 15px; }

/*
 *	IMPORTS
 */
/*
 *	Tijdelijke hacks en bugfixes in afwachting van de volgende Bootstrap release
 */
/*
 *	Bootstrap 4.2a: grid fix voor IE10+
 */
.col-xs-12 {
  max-width: 100%; }

.col-xs-11 {
  max-width: 91.666667%; }

.col-xs-10 {
  max-width: 83.333333%; }

.col-xs-9 {
  max-width: 75%; }

.col-xl-8 {
  max-width: 66.666667%; }

.col-xs-7 {
  max-width: 58.333333%; }

.col-xs-6 {
  max-width: 50%; }

.col-xs-5 {
  max-width: 41.666667%; }

.col-xs-4 {
  max-width: 33.333333%; }

.col-xs-3 {
  max-width: 25%; }

.col-xs-2 {
  max-width: 16.666667%; }

.col-xs-1 {
  max-width: 8.333333%; }

@media (min-width: 544px) {
  .col-sm-12 {
    max-width: 100%; }
  .col-sm-11 {
    max-width: 91.666667%; }
  .col-sm-10 {
    max-width: 83.333333%; }
  .col-sm-9 {
    max-width: 75%; }
  .col-sm-8 {
    max-width: 66.666667%; }
  .col-sm-7 {
    max-width: 58.333333%; }
  .col-sm-6 {
    max-width: 50%; }
  .col-sm-5 {
    max-width: 41.666667%; }
  .col-sm-4 {
    max-width: 33.333333%; }
  .col-sm-3 {
    max-width: 25%; }
  .col-sm-2 {
    max-width: 16.666667%; }
  .col-sm-1 {
    max-width: 8.333333%; } }

@media (min-width: 768px) {
  .col-md-12 {
    max-width: 100%; }
  .col-md-11 {
    max-width: 91.666667%; }
  .col-md-10 {
    max-width: 83.333333%; }
  .col-md-9 {
    max-width: 75%; }
  .col-md-8 {
    max-width: 66.666667%; }
  .col-md-7 {
    max-width: 58.333333%; }
  .col-md-6 {
    max-width: 50%; }
  .col-md-5 {
    max-width: 41.666667%; }
  .col-md-4 {
    max-width: 33.333333%; }
  .col-md-3 {
    max-width: 25%; }
  .col-md-2 {
    max-width: 16.666667%; }
  .col-md-1 {
    max-width: 8.333333%; } }

@media (min-width: 992px) {
  .col-lg-12 {
    max-width: 100%; }
  .col-lg-11 {
    max-width: 91.666667%; }
  .col-lg-10 {
    max-width: 83.333333%; }
  .col-lg-9 {
    max-width: 75%; }
  .col-lg-8 {
    max-width: 66.666667%; }
  .col-lg-7 {
    max-width: 58.333333%; }
  .col-lg-6 {
    max-width: 50%; }
  .col-lg-5 {
    max-width: 41.666667%; }
  .col-lg-4 {
    max-width: 33.333333%; }
  .col-lg-3 {
    max-width: 25%; }
  .col-lg-2 {
    max-width: 16.666667%; }
  .col-lg-1 {
    max-width: 8.333333%; } }

@media (min-width: 1200px) {
  .col-xl-12 {
    max-width: 100%; }
  .col-xl-11 {
    max-width: 91.666667%; }
  .col-xl-10 {
    max-width: 83.333333%; }
  .col-xl-9 {
    max-width: 75%; }
  .col-xl-8 {
    max-width: 66.666667%; }
  .col-xl-7 {
    max-width: 58.333333%; }
  .col-xl-6 {
    max-width: 50%; }
  .col-xl-5 {
    max-width: 41.666667%; }
  .col-xl-4 {
    max-width: 33.333333%; }
  .col-xl-3 {
    max-width: 25%; }
  .col-xl-2 {
    max-width: 16.666667%; }
  .col-xl-1 {
    max-width: 8.333333%; } }

/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (min-width: 992px) {
  .container {
    max-width: 80%; } }

@media (min-width: 1850px) {
  .container {
    max-width: 1480px; } }

@media (max-width: 1199px) {
  .logo {
    max-width: calc(100% - 200px);
    margin-bottom: 30px; }
  .two-cols__col {
    float: none;
    width: 100%; } }

@media (max-width: 800px) {
  body {
    font-size: 1rem; }
  .grid__item {
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    width: 100%;
    max-width: 100%; }
  .card-block {
    padding: 1vh; } }

@media (max-width: 991px) {
  .logo {
    max-width: calc(100% - 200px); } }

@media (max-width: 767px) {
  .header {
    margin-bottom: 30px; }
  .logo {
    margin-left: 0;
    max-width: calc(100% - 190px); }
    .logo img {
      max-width: 100%; }
  .toggle-nav {
    height: 43px;
    margin: 0 20px 0 -10px; }
  .social {
    margin-top: 0; } }

@media (max-width: 430px) {
  .logo {
    max-width: calc(100% - 40px);
    margin-bottom: 10px; }
  .logo__img {
    margin-right: 0; }
  .social a,
  .dropdown--subscribe .btn {
    font-size: 16px; } }

@media (max-width: 380px) {
  .page__content {
    padding-left: 25px; }
  .breadcrumb {
    margin-left: 25px; } }

/*
 *	RESPONSIVE NAV
 */
/* larger screens */
@media (min-width: 800px) {
  .page__nav {
    width: 5px;
    position: relative;
    overflow-x: hidden;
    transition: width .15s ease-out; }
  .page__content {
    width: calc(100% - 35px);
    transition: width .15s ease-out; }
  .nav--side {
    opacity: 0;
    transition: opacity .15s ease-out; }
  .is-nav-open .page__nav,
  .has-children .page__nav {
    width: 33%; }
  .is-nav-open .page__content,
  .has-children .page__content {
    width: 67%; }
  .is-nav-open .nav--side,
  .has-children .nav--side {
    opacity: 1; } }

/* smaller screens */
@media (max-width: 800px) {
  /* responsive nav */
  body {
    overflow-x: hidden; }
  .page {
    position: relative; }
  .page__nav {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 5px;
    padding-right: 0;
    transition: width 0.3s ease-out; }
  .page__content {
    width: 100%;
    margin-left: 0;
    padding-left: 35px;
    transition: transform 0.3s ease-out; }
  .nav--side {
    width: 290px; }
    .nav--side li {
      transform: translateX(-100%);
      transition: transform 0.3s ease-out; }
  .is-nav-open .page__content {
    transform: translateX(320px); }
  .is-nav-open .page__nav {
    width: 320px;
    padding-right: 30px; }
  .is-nav-open .nav--side li {
    transform: translateX(0); }
  .is-nav-open .footer {
    opacity: 0;
    pointer-events: none; } }

/*# sourceMappingURL=app.css.map */
